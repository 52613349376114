import React from "react"
import { Seo } from "../../components/seo"

const ThankyouEs = () => {
  return (
    <>
      <Seo title={`Mexico thankyou page`} language={"en"} />
      <section className="container thankyou-en english-page-body">
        <div className="row">
          <div className="en-pages">
            <p>¡Gracias por postularte! Responderemos lo antes posible.</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default ThankyouEs
